import axios from '@/utils/axios';
const baseURL = '/api'

// 就业信息列表
export const getEmergencyNotificationList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/notice/employmentInformation',
    method: 'get',
    params
  });
};