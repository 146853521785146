 
<template>
  <van-list
    v-model="loading"
    :finished="finished"
    :finished-text="finishedText"  
    @load="onLoad"
    >
    <div class="diyCell" v-for="item in list" :key="item.idStr" @click="toDetail(item)">
      <div class="top">
        <div class="title van-ellipsis">{{ item.title }}</div>
         <van-tag size="medium" color="#dbedf9" text-color="#0080cb">{{ item.typeName }}</van-tag>

      </div>
      <div class="time">
        <span>{{ item.createTime }}</span>
        <van-icon name="arrow" />
      </div>
    </div>
    </van-list>
</template>

<script>
import { getEmergencyNotificationList } from '@/api/emergencyApi' 

export default {
  name: 'EmergencyNotification',
  components: {
  }, 
  data() {
    return {
        list: [],
        current: 1,
        currentList: -1,
        loading: false,
        finished: false,
        finishedText: '' 
    }
  },
  // 计算属性
  computed: {},

  // watch
  watch: {},

  // 创建完成（可以访问当前this实例）
  created() { 
  }, 
  mounted() {},   
  // 方法集合
  methods: {
    // 跳转到详情页
    toDetail(item) {       
      this.$router.push({path: '/employmentInformationDetail', query: {id: item.id}})
    }, 
    async onLoad() {    
      if (this.currentList !== 0) {  
        const res = await getEmergencyNotificationList({
          current: this.current,
          size: 10,
          tenantId: this.$store.getters.userInfo.tenantId,
          category: 9,
          createDept: this.$store.getters.userInfo.deptId
          })   
        this.currentList = res.data.records ? res.data.records.length : 0
        this.list = this.list.concat(res.data.records)
        this.current++ 
        this.loading = false 
      } else {
        this.finished = true; 
        this.finishedText = '没有更多了'
      } 
    },
  }
}
</script>

<style lang='scss'>
</style>
<style lang='scss' scoped>
// @import url(s); 引入公共css类
.diyCell {
  width: 94%;
  margin-left: 3%;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 5px 5px #efefef;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    .title {
      flex: 1;
      // font-weight: 600;
      font-size: 14px;
    }
  }
  p {
    height: 48px;
    margin-bottom: 6px;
    font-size: 12px;
    color: #5f5f5f;
  }
  .time {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f3f3f3;
    color: #b9b9b9;
    font-size: 12px;
  }
}
</style>
